.assignment-card {
    background: #FFFFFF;
    min-height: 5rem;
    padding: 0.75rem 1rem 0.75rem 5rem;
    cursor: pointer;
    position: relative;
    border-bottom: 1px solid #eee;
    // cursor: pointer;

    &:focus {
        outline: 2px solid #007FA3;
        outline-offset: -5px;
    }

    .icon-holder {
        position: absolute;
        left: 1rem;
        top: 1rem;
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;

        > svg {
            fill: white;
        }
        &.video {
            background-color: #647A00;
        }

        &.activity {
            // background-color: #C45303;
            background-color: #9348D4;
        }

        &.other {
            background-color: #297F94;
        }
        &.reading {
            // background-color: #566BB7;
            background-color: #5066C5;

        }

        &.question {
            background-color: #753299;
        }
    }

    .item-title {
        font-size: 0.875rem;
        line-height: 1.3;
        letter-spacing: 0.0005em;
        font-weight: 600;
        margin-bottom: 0.5rem;
        line-height: 1.5;
        margin-right: 2.5rem;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;  
        overflow: hidden;
        display: -webkit-box;
    }

    .item-meta-data {
        display: flex;
        gap: 0.5rem;
        list-style-type: none;
        align-items: center;
        margin: 0 !important;
        padding: 0;

        li + li{
            padding-left: 0.5rem; 
        }
    }

    .type-chip {
        font-size: 0.75rem;
        font-weight: 600;
        color: #333;
        letter-spacing: 0.14px;
        text-transform: capitalize;
        white-space: nowrap;
        padding-left: 0 !important;
    }

    .item-time, .item-points {
        color: #333;      
        font-weight: 400;
        font-size: 0.75rem; text-align: center;
    }

    .item-time {
        white-space: nowrap;
        border-left: 1px solid rgba(0, 0, 0, 0.23);
    }

    .item-points {
        // border-left: 1px solid rgba(0, 0, 0, 0.23);
        // padding-left: 0.5rem;
    }

    .item-status {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;

        &.completed {
            // background-color: rgba(3, 130, 56, 0.15);
        }

        svg {
            &.status-not-started {
                fill: #fff;
            }

            &.status-complete {
                fill: #038238;
            }
        }
    }

    &.in-overview {
        .item-title {
            font-size: 1rem;
        }
        .item-status {
            svg {
                &.status-not-started {
                    display: none;
                }
            }
        }
    }

    &:hover {
        // background: rgba(51, 51, 51, 0.02);  
        // background-color: #e1dada;
        background-color: rgba(233,233,233,1);

        .item-title {
            text-decoration: underline;
        }
        .item-status {
            opacity:1;
        }
    }

    &[disabled] {
        background-color: #FAFAFA;

        .icon-holder {
            background-color: #888888 !important;
        }

        &:hover {
            cursor: default;
            background-color: #fafafae0;

            .item-title {
                text-decoration: none;
            }
        }

        &:focus {
            outline: none;
        }

        .item-status {
            background-color: #f0f0f0;
            opacity: 0.8;

            svg {
                fill: rgba(0,0,0, 0.2);
            }
        }
    }
    &.selected {
        .item-title, .item-meta-data, .item-time, .item-points, .type-chip {
            color: #fff;
            border-color: rgba(255,255,255, 0.5);
        }

        .icon-holder {
            background-color: rgba(255, 255, 255, 0.2) !important;
        }

        &:focus {
            outline-color: #fff;
        }

        &:hover {
            background-color: inherit;
        }

        &.video {
            background-color: #647A00;
            
            .item-status {
                background-color: rgba(255,255,255, 0.25);

                .status-not-started {
                    fill: #7D9900;

                    circle {
                        stroke: white;
                        stroke-opacity: 1;
                    }
                }

                .status-complete {
                    fill: white;
                }
            }
        }

        &.activity {
            // background-color: #C45303;
            background-color: #9348D4;
            
            .item-status {
                background-color: rgba(255,255,255, 0.25);

                .status-not-started {
                    // fill: #CC5803;
                    fill: #9348D4;

                    circle {
                        stroke: white;
                        stroke-opacity: 1;
                    }
                }

                .status-complete {
                    fill: white;
                }
            }
        }
        
        &.other {
            background-color: #297F94;
            .item-status {
                background-color: rgba(255,255,255, 0.25);

                .status-not-started {
                    fill: #297F94;

                    circle {
                        stroke: white;
                        stroke-opacity: 1;
                    }
                }

                .status-complete {
                    fill: white;
                }
            }
        }

        &.reading {
            background-color:#5066C5; /*#566BB7;*/
            
            .item-status {
                background-color: rgba(255,255,255, 0.25);

                .status-not-started {
                    fill: #5066C5; /*#566BB7;*/

                    circle {
                        stroke: white;
                        stroke-opacity: 1;
                    }
                }

                .status-complete {
                    fill: white;
                }
            }
        }
               
        &.question {
            background-color: #753299;
            
            .item-status {
                // background-color: rgba(255,255,255, 0.25);

                .status-not-started {
                    fill: #7C03B5;

                    circle {
                        stroke: white;
                        stroke-opacity: 1;
                    }
                }

                .status-complete {
                    fill: white;
                }
            }
        }
    }
}

/* Collapsed Nav styles */
.collapsed {
    .assignment-group {
        margin: 0 !important;    
        box-shadow: none;
        gap: 0;

        li {
            &:first-child, &:last-child {
                .assignment-card {      
                    border-radius: 0;

                    .item-status {
                        border-radius: 0;
                    }
                }
            }
            
            .assignment-card {
                border-radius: 0;
                height: 3rem;
                border: 0;        
                padding: 0.75rem 0.5rem 0.5rem;
                border-bottom: 1px solid #eee;
                min-height: 0;
                
                .item-title, .type-chip, .item-time, .icon-holder, .item-points {
                    display: none !important;
                }

                .item-status {
                    grid-column: 1 / span 7;
                    width: 100%;

                    &.completed {
                        background-color: transparent;
                    }
                }

                &:hover {
                    // background-color: inherit;
                    &.video {
                        background-color: #647a0080;
                    }
            
                    &.activity {
                        background-color: #9348D480;
                    }
            
                    &.other {
                        background-color: #297F9480;
                    }
                    &.reading {
                        background-color: #5066C580;
                    }
            
                    &.question {
                        background-color: #75329980;
                    }
                }

                &.selected {
                    .item-status {
                        &.completed {
                            background-color: rgb(3, 130, 56); 
                        }
                    }
                }
            }
        }
    }
}


.assignment-group {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap:12px;
    // border-radius: 0.5rem;
    margin: 1rem 0;
    // box-shadow: 0px 1px 2px rgba(84, 110, 122, 0.24), 0px 2px 4px rgba(120, 144, 156, 0.24);

    &.standalone {
        padding:0; 
        .assignment-card {
            background: #FFFFFF66;
            border: 1px solid #ffffffCC;
            border-radius:16px;
            // padding: 16px;
        }
    }
    &:first-child {
        margin-top: 0;
    }

    li {
        .assignment-card {
            border-radius: 0.5rem;
        }
    }
}
.assignment-group-btn {
    // background: #FFFFFF;
    background:transparent;
    // background:rgba(255, 255, 255, 1);
    min-height: 5rem;
    padding: 16px;
    cursor: pointer;
    position: relative;
    border:0;
    border-radius:0.5rem;
    // border-bottom: 1px solid #eee;
    margin-bottom:1px;
    display:flex;
    align-items: center;
    // justify-content: space-between;
    gap:20px;
    width:100%;
    font-size:1.125rem;
    text-align:left;

    .feature-image {
        width:78px;
        height:78px;
        overflow:hidden;
        border-radius:8px;

        img {
            object-fit: cover;
            height:100%;
            width:100%;
        }
    }
    .icon {
        display:flex;
        align-items: center;
        justify-content: center;
        width:44px;
        height:44px;
        border-radius:50%;
        background: #FFFFFF99;
        border: 1px solid #FFFFFFCC;
        svg {
            fill:#546E7A;
            transition:transform 0.15s ease-in-out;
        }
    }
    .assignment-info {
        flex:1;
        max-width:calc(100% - 76px);
    }
    .group-title {
        display:block;
        line-height:1.5;
        font-weight:600;
        margin-bottom:0.25rem;
    }
    .item-meta-data {
        display: flex;
        flex-direction:column;
        gap: 0.5rem;
        list-style-type: none;
        align-items: flex-start;
        margin: 0 !important;
        padding: 0;

        li {
            // padding-left: 0.5rem; 
            font-size:0.825rem;
            &:first-child {
                padding-left:0;
            }
        }
        .item-progress {
            display:flex;
            align-items: center;
            gap:8px;
        }
        .progress-bar-label {
            white-space:nowrap;
        }
        .progress-bar {
            width:100%;
            height:6px;
            min-width:114px;
            position:relative;
            background-color:white;
            border-radius:4px;
            .bar {
                position:absolute;
                left:0;
                top:0;
                height:6px;
                background-color:#009EBE;
                border-radius:4px;
            }
        }
    }
    .expand-btn {
        background:transparent;
        border:none;
        cursor: pointer;
        flex:none;
        &:hover .icon{
            border: 1px solid #54545566;
        }
        &[aria-expanded=true] {
            svg {
                transform:rotate(180deg);
            }
        }
    }
    &[aria-expanded=true] {
        border-radius:0.5rem 0.5rem 0 0;
        .text {
            font-weight:bold;
        }
        .icon {
            svg {
                transform:rotate(180deg);
            }
        }
    }
    
}
.assignment-group-container {
    // background: #FFFFFF66;
    background:rgba(255,255,255,0.3);
    border: 1px solid #FFFFFFCC;
    border-radius:16px;
    // padding: 16px;
    margin: 1rem 0;
    // box-shadow: 0px 1px 2px rgba(84, 110, 122, 0.24), 0px 2px 4px rgba(120, 144, 156, 0.24);
    // filter: drop-shadow(0px 1px 2px rgba(84, 110, 122, 0.24));
    &:first-child {
        margin-top: 0;
    }
    .assignment-group {
        // padding: 16px 32px;
        padding: 16px;
        margin:0;
        box-shadow: none;
        // max-height:100%;
        animation: 0.1s hide forwards;
        &.closed {
            padding:0;
            animation: 0.1s hide forwards;
            // animation-delay:0.3s;
        }
        &.open {
            animation: 0.6s hide;
            animation-delay:0.3s;

            
        }
        .assignment-card {
            padding: 0.75rem 1rem;
            .icon-holder {
                display:none;
            }
        }
        
    }
    button + .assignment-group {
        &.open {
            li {
                // &:first-child .assignment-card{
                //     border-radius:0;
                //     .item-status {
                //         border-radius: 0;
                //     }
                // }
                
            }
        }
    }
}

.card-btn {
    background:transparent;
    border:none;
    color: #604BCE;
    // font-family: "Proxima Nova";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 0 8px;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}
@keyframes hide {
    0% {
        visibility: visible;
    } 
    100% {
        visibility: hidden;
        max-height:0;
        overflow:hidden;
    }
}