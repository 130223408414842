.overview-hero {    
    // position: sticky;
    // top: 0;
    padding: 2.5rem 0;
    min-height: 16rem;
    z-index: 2;
    // box-shadow: 0px 10px 20px rgba(255, 255, 255, 0.7);
    // border-bottom: 1px solid rgba(163, 200, 211, 0.5);

    @include devices(small) {
        padding: 1rem 0;
    }

    &.pinned {
        @include devices(small) {
            min-height: 0;
        }
       .meta-data, .overview-hero__right, .progress-indicator {
            @include devices(small) {
                display: none;
            }
        }

        .action-bar {
            @include devices(small) {
                grid-row: 2;
            }
        }
    }

    .gradient {
        background: linear-gradient(80.83deg, rgba(232, 203, 192, 0.1) 33.76%, rgba(99, 111, 164, 0) 93.73%),linear-gradient(87.2deg, rgba(255, 255, 255, 0.9) 19.77%, rgba(255, 255, 255, 0) 99.48%), rgba(4, 122, 156, 0.4);   
        position: absolute;
        top: 0;
        height: 100%;
        left: 0;
        width: 100%;
        z-index: 6;
    
        &:before {
            content: '';
            position: absolute;
            top: 0;
            width: 100%;
            left: 0;
            height: 100%;
            opacity: 0.5;
            background: linear-gradient(181.53deg, rgba(232, 203, 192, 0.8) -1.76%, rgba(99, 111, 164, 0.096) 98.7%);
            z-index: 5;
        }
    }

    &:after {
        // content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.95) 85%, rgba(255,255,255,0.3) 100%);
        z-index: 2;
    }

    .wrapper {
        
        position: relative; 
        z-index: 10;

        
        .card {
            // background:rgba(255,255,255,0.4);
            // -webkit-backdrop-filter: blur(5px);
            // backdrop-filter: blur(5px);
            padding: 1.5rem 1.25rem;
            // border-radius:0.5rem;
            // display: grid;
            // grid-template-columns: 52% 40%;
            // justify-content: space-between;
            opacity: 1;
            @include devices(medium) {
                grid-template-columns: 100%;
                align-items: flex-start;
                gap: 1.25rem;
    
            }
        }
    }

    &__left {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        margin-bottom: 0.75rem;
        

        @include devices(medium) {
            margin-bottom: 0;
            
        }
        @media (min-width:768px) {
            max-width:70%;
        }
        
        .gr-btn {
            // max-width: 8.25rem;
        }
    }

    &__right {
        grid-column: 2;
        grid-row: 1 / span 2;
            
        @include devices(medium) {
            grid-row: 2;
            grid-column: 1;
        }
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        &.meta-data {
            display: flex;
            align-items:center;
            gap:20px;
            // gap: 1rem;
            // color: #333333;
            color:var(--text-color, #ffffff);
            flex-wrap: wrap;
            margin: 0 !important;
            
            // @include devices(small) {
            //     gap: 0.75rem;
            // }

            a {
                font-weight: 600;
                // color:var(--text-color, #ffffff);
            }

            
            li {
                color:inherit;
                // padding:  0 0.5rem;
                // border-right: 2px solid rgba(0, 0, 0, 0.2);
                
                + li {
                    margin-left:0.75rem;
                }
            }
            .due-date {
                // background: linear-gradient(112.36deg, #7066AE 0.67%, #867EB7 62.65%, #BAB0F8 110.36%);
                background: var(--Icons-Informativ, #009EBE);
                padding: 8px 16px;
                text-align:center;
                color:white;
                border-radius:8px;
                font-weight:600;
                font-size:1rem;
                line-height:1.5;
            }
            .label {
                font-weight: 600;
                margin-right: 0.25rem;
            }

            &.points {
                // gap: .25rem;
                // li {
                //     padding:  0 0.5rem;
                //     border-right: 1px solid rgba(0, 0, 0, 0.54);

                //     &:last-child{
                //         border: 0;
                //         padding-right: 0;
                //     }

                //     &:first-child {
                //         padding-left: 0;
                //         padding-right: 0.75rem;
                //     }
                // }
            }
        }        
    }

    .assignment-type {
        font-size: 0.875rem;
        // font-weight: 300;
        color: black;
        display: flex;
        align-items: center;
        text-transform: capitalize;
        margin: 0;
        // background-color: #76D5D4;
        // padding:0 8px;
        // border-radius:4px;
        width:fit-content;

        &:before {
            content: "";
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            background-color: #017899;
            margin-right: 0.5rem;
        }
    }

    h1 {
        // font-weight: 400;
        // font-size: 2.125rem;
        // line-height: 1.3;
        // margin-bottom:0.5rem;
        color:#232323;
        //styleName: Header/H1XL;
        // font-family: Proxima Nova;
        font-size: 64px;
        font-weight: 400;
        line-height: 83.2px;
        text-align: left;


        .assignment-chapter {
            // font-family: Proxima Nova;
            display:block;
            font-size: 21px;
            font-weight: 700;
            line-height: 25.58px;
            letter-spacing: 3.740000009536743px;
            text-align: left;
            margin-bottom:35px;
            text-transform: uppercase;
            color: #1A1A1ACC;
        }

        @include devices(small) {
            font-size: 1.5rem;
            font-weight: 400;
        }
    }

    .action-bar {
        display: flex;
        align-items: flex-end;
        gap: 1.5rem;
        margin-top: 1.25rem;
            
        @include devices(small) {
            grid-row: 3;
            gap: 1rem;
        }

        .gr-btn {
            margin: 0;
            padding:16px 24px;
            // border-radius: 55.961px;
            border: 1px solid #FFF;
            background: var(--Background-Whtie, #FFF);
            backdrop-filter: blur(27.977977752685547px);
            filter: drop-shadow(0px 4px 9px rgba(89, 89, 89, 0.10)) drop-shadow(0px 17px 17px rgba(89, 89, 89, 0.09)) drop-shadow(0px 39px 23px rgba(89, 89, 89, 0.05)) drop-shadow(0px 69px 27px rgba(89, 89, 89, 0.01)) drop-shadow(0px 107px 30px rgba(89, 89, 89, 0.00));
            color: var(--Text-Primary, #232323);
            text-align: center;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 600;
            letter-spacing: 1.695px;
            text-transform: uppercase;

            @include devices(small) {
                display: block;    
                width: 100%;    
            }
        }
        
        @include devices(small) {
            flex-direction: column;
            align-items: flex-start;    
            width: 100%;        
        }
    }
}

.progress-indicator {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    transition: all 0.5s ease-in-out;

    .progress-info {
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 1.4;
    }

    .progress {
        width: 100%;
        min-width: 18rem;
        height: 9px;
        border-radius: 99px;
        // background:rgba(231, 231, 231, 1);
        background-color: rgba(0,0,0,0.1);
        position: relative;
        // padding:0.125rem;

        @include devices(large) {
            min-width: 12rem;
        }

        span {
            display: block;
            // position: absolute;
            left: 0;
            top: 0;
            height: 9px;
            border-radius: 9px;
            // background: linear-gradient(270deg, #06A5C8 0%, #007899 100%);
            // background: linear-gradient(86.65deg, #1CE6B5 -81.59%, #7075F7 100%);
            // background:#02835C;
            background:rgba(0, 0, 0, 0.50);
        }
    }
}

.overall-score {
    // background: linear-gradient(261.73deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.5) 100%);
    // background: rgba(255, 255, 255, 1);
    
    
    border-radius: 0.5rem;
    width: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    flex-wrap:wrap;
    gap: 0.5rem;
    // height: 11.25rem;
    color:var(--text-color, #ffffff);

    h3 {
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
        color:var(--text-color, #ffffff);

        @include devices(small) {
            font-size: 1.125rem;
        }

        + span {
            @include devices(small) {
                font-size: 1rem;
            }
        }
    }

    &.in-progress {
        // background-image: linear-gradient(261.73deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.5) 100%);
    }

    &.complete {
        // background-image: linear-gradient(261.73deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.5) 100%);
        // background-position: right bottom;
    }

    @include devices(large) {
        padding: 1.5rem;
        // background: linear-gradient(261.73deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.5) 100%); 
        // background-size: 50%, auto;
    }

    @include devices(medium) {        
        background-size: auto, auto;
        // flex-direction: column;
    }

    @include devices(small) {
        // background: linear-gradient(261.73deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.5) 100%);
        // background-size: contain;
        height: 8.25rem;
    }
}